:root {
  --main-color: #00428F;
}

body {
  margin: 0;
  font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none !important;
}

b, strong {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
}

.ql-editor {
  min-height: 300px !important;
  max-height: 500px;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: scroll;
}

* {
  scrollbar-width: thin;
  scrollbar-color: gray transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 7px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 20px;
  border: 3px solid transparent;
}

.grecaptcha-badge { 
  visibility: hidden;
}
