.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif;
  /*font-size: calc(10px + 2vmin);*/
  /*color: white;*/
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1,
h2,
h3,
h4,
h5,
p {
  padding: 0px;
  margin: 0px;
}

* {
  box-sizing: border-box;
}

.ql-editor h2 {
  font-size: 1.25em !important;
  margin-top: 0.5em;
}

.Toastify__toast-container {
  width: 320px;
}
.Toastify__toast--default {
  background: #e31f25 !important;
  color: white !important;
  /*TODO unknown red color as default */
}
.Toastify__toast--info {
  background: #0099E5;
}
.Toastify__toast--success {
  background: #169E3E;
}
.Toastify__toast--warning {
  background: #D53C3C;
}
.Toastify__toast--error {
  background: #A11028;
}

.Toastify__close-button {
  color: white !important;
}